import React from "react"
// components
import Layout from "../components/Layouts/layout"
import SEO from "../components/seo"
import {
    FirstScreen,
    Advantages,
    SizeOfRates,
    StagesOfWork,
    GrowthChart
} from '../components/GreenTariff'
// global
import { FormOrder, Breadcrumbs } from '../components/Global'
// context
import YAMLGlobal from "../content/ru/global.yaml"
import YAMLGreen from "../content/ru/green.yaml"
import { graphql, useStaticQuery } from "gatsby"

const GreenTariffPage = ({location}) => {
    const data = useStaticQuery(graphql`
        query {
            seo: directusGreenTariffSeo {
                title
                description
            }
            mainInfo: directusGreenTariffMain {
                description
                main_image {
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 825) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                value_of_bets_image {
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 892) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                graphics_title
                graphics_top
                graphics_right
            }
            advantages: allDirectusGreenTarifAdvantage(sort: {fields: sort}) {
                edges {
                    node {
                        id
                        icon {
                            localFile {
                                publicURL
                            }
                        }
                        description
                    }
                }
            }
            steps: allDirectusGreenTarifStepsWork(sort: {fields: sort}) {
                edges {
                    node {
                        id
                        title
                        description
                        image {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 618) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)


    const list = [
        {
            link: "/",
            title: YAMLGlobal.breadCrumbs.main
        },
        {
            link: '/green-tariff',
            title: YAMLGlobal.breadCrumbs.greenTariff
        }
    ]
    
    const greenTariffLocal = {
        langBase: "",
        YAMLGlobal,
        YAMLContent: YAMLGreen
    }
    
    return(
        <Layout local={greenTariffLocal} location={location}>
            <SEO title={data.seo.title} description={data.seo.description} />
            <Breadcrumbs list={list}/>
            <FirstScreen
                description={data.mainInfo.description}
                image={data.mainInfo.main_image.localFile?.childImageSharp.fluid}
            />
            <Advantages
                data={data.advantages.edges}
            />
            <SizeOfRates
              image={data.mainInfo.value_of_bets_image.localFile?.childImageSharp.fluid}
            />
            <StagesOfWork stages={data.steps.edges}/>
            <GrowthChart
              title={data.mainInfo.graphics_title}
              topText={data.mainInfo.graphics_top}
              rightText={data.mainInfo.graphics_right}
            />
            <FormOrder/>
        </Layout>
    )
}

export default GreenTariffPage;